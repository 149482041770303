import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../App";
import { ErrorBoundary } from "../other/utils/ErrorBoundary";

import { baseUrl } from "../App";

import '../assets/base.scss'
import '../assets/style.css'

import LoginHelpModal from '../other/utils/LoginHelpModal'


export default function Login() {

    const {
        studentDataFunctions: {
            handleResetStudent,
            setStudent,
            setClassInformation, checkCalendar,
            setLoginState, getMongoById,
        },
        studentList,
        classInformationArray
    } = useContext(SiteContext)

    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate();

    async function findStudent(studentId) {
        try {
            const dailyData = await getMongoById(studentId, 'dailyList')
            const recordData = await getMongoById(studentId, 'records')
            console.log({...recordData, ...dailyData},'{ ...dailyData, ...recordData}', dailyData, recordData)
            return {...recordData, ...dailyData}
        } 
        catch(err) {
            console.log(err)
        }
    }

    async function handleStudentLookup (e) {

        e.preventDefault()
    
        const email = e.target.email.value.toLowerCase()
        const password = e.target.password.value.toString()

        if(password.length === 0 || email.length === 0) return

        // fetchData(password)
            var student = await findStudent(password)
            var emailData = (student && student.email) ? student.email.toLowerCase() : false
            if(student && email === emailData) {
                console.log(student,'student')
                const filtered = classInformationArray.filter(entry => entry.period === student.period)[0]
                filtered.dayType = checkCalendar() ? checkCalendar() : 'Normal'
                setStudent(prev => {
                    return {...prev, ...student, loginTimeStamp: Date.now(), loggedIn: true, todayTimestamp: Date.now()}
                })
                setClassInformation(filtered)
                setLoginState({ admin: false, loggedIn: true })
                navigate('/checkin');
            } else if (email === 'alexander.smith@wccusd.net' && password === '145945smith') {
                setLoginState({ admin: true, loggedIn: true })
                var student = await findStudent('123456')
                setStudent(prev => {
                    return {...prev, ...student, loginTimeStamp: Date.now(), loggedIn: true, todayTimestamp: Date.now()}
                })
                navigate('/choosePeriod');
            } else {
                alert('Login Failed. Please try again!')
            }
    }

    function toggleShowPassword() {
        setPasswordShown(!passwordShown);
    }

    async function fetchData(password) {
      
        // console.log(password, 'pw')

        const response = await fetch(`${baseUrl}/readOne/records/studentId/${password}`);
    
        if (!response.ok) {
          const message = `An error has occurred find student: ${response.statusText}`;
          alert(message);
          return;
        }
    
        const record = await response.json();
        if (!record) {
          alert(`Record with id ${password} not found`);
          navigate("/recordsNew");
          return;
        }
    
        console.log(record, 'record')
        // alert(record.name + ' name')
    }

    return (
      <ErrorBoundary>
        <div className="app-container app-theme-white body-tabs-shadow">
            {/* <div className="app-container"> */}
            <ErrorBoundary>
                <div className="h-100 bg-plum-plate bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <div className="mx-auto app-login-box col-md-8">
                    <div className="app-logo-inverse mx-auto mb-3" />
                        <div className="modal-dialog w-100 mx-auto">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="h5 modal-title text-center" style={{paddingBottom: "20px"}}>
                                        <h4 className="mt-2"/>
                                        <div style={{paddingBottom:"10px"}}>Welcome to Class!</div>
                                        <h5>Please sign in below.</h5>
                                    </div>
                                    <form 
                                    onSubmit={(e) => {
                                        // handleLookup(e)
                                        handleResetStudent()
                                        handleStudentLookup(e)
                                    }}
                                    >
                                        <div className="">
                                        <div className="col-md-12">
                                            <div className="position-relative mb-3">
                                            <input
                                                name="email"
                                                id="email"
                                                placeholder="School email here..."
                                                type="email"
                                                className="form-control"
                                                // defaultValue="alex@sska.net"
                                                // defaultValue="alexander.smith@wccusd.net"                                
                                            />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="position-relative mb-3">
                                            <input
                                                name="password"
                                                id="passwordForm"
                                                placeholder="Student ID # here..."
                                                type={passwordShown ? "text" : "password"}
                                                className="form-control"
                                                // defaultValue="145945"
                                                // defaultValue={445945}                                
                                            />
                                            <input type="checkbox" onChange={toggleShowPassword}/> Show Password
                                            </div>
                                        </div>
                                        </div>
                                        <div className="float-start">
                                            <LoginHelpModal />
                                            {/* <a 
                                              className="btn btn-link"
                                              onClick={(e) => {handleHelpClick(e)}}
                                            >
                                            Ask For Login Help
                                            </a> */}
                                        </div>
                                        <div className="float-end">
                                            <button type="submit" className="btn btn-primary">Login</button>
                                        </div>               
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <div className="float-start">
                                        <span style={{fontWeight:"bold"}}>Email:&nbsp; </span>your.name@wccschools.org
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            {/* </div> */}
            </ErrorBoundary>
        </div>
      </ErrorBoundary>
    )
}
