import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from "react-router";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SiteContext, baseUrl } from "../../App";

export default function LoginModalHelp({person, title, edit, variant}) {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({
      name: '',
      reason: 'Login Problems',
      message: '',
      period: ''
  })

  const { socket, room, classInformationArray,
    studentDataFunctions: {
        setRoom,
        joinRoom,
        sendMessage
    }
 } = useContext(SiteContext)
 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleHelpClick(e) {
    e.preventDefault()

    if(form.name.length < 2 || form.period === '-- Select Your Period --') {return}
    handleClose()

    sendMessage(form)

  }

  useEffect(() => {
    if(form.name.length === 0) {return}
    setRoom(form.period)
    joinRoom('Student Help', form.period)

  }, [form.period])
  
  return (
    <>
      <Button 
        variant="light" 
        onClick={handleShow}
        // style={{width:"47%", textAlign: "center", marginRight:"5px"}}
      >
        Ask for Help
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send Help Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="position-relative mb-3">
                <label htmlFor="exampleSelect" className="form-label">Name: &nbsp;</label>
                <input
                    placeholder="Type your name here . . ."
                    type="text"
                    className="mb-2 form-control"
                    style={{width:"", display:"inline-block"}}
                    value={form.name}
                    onChange={(e) => setForm({...form, name: e.target.value})}
                />
            </div>
            <div className="position-relative mb-3">
                <label htmlFor="exampleSelect" className="form-label">Message: &nbsp;</label>
                <input
                    placeholder="Type problem here . . ."
                    type="text"
                    className="mb-2 form-control"
                    style={{width:"", display:"inline-block"}}
                    value={form.message}
                    onChange={(e) => setForm({...form, message: e.target.value})}
                />
            </div>
            <div className="position-relative mb-3">
                <label htmlFor="exampleSelect" className="form-label">Your Period: &nbsp;</label>
                <select
                  value={form.period}
                  onChange={(e) => {setForm({...form, period: e.target.value})}}
                >
                    <option>-- Select Your Period --</option>
                    {classInformationArray.map(entry => {
                      return <option key={entry.period}>{entry.period}</option>
                    })}
                </select>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            onClick={(e) => {
                handleHelpClick(e)
            }}>
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}